import buqueFigura0 from './buqueFigura0.png'
import buqueFigura2 from './buqueFigura2.png'
import buqueFigura3 from './buqueFigura3.png'
import buqueFigura4 from './buqueFigura4.png'
import buqueFigura5 from './buqueFigura5.png'
import buqueFigura6 from './buqueFigura6.png'
import buqueFigura7 from './buqueFigura7.png'
import buqueFigura8 from './buqueFigura8.png'

export default [
  buqueFigura0,
  buqueFigura2,
  buqueFigura2,
  buqueFigura3,
  buqueFigura4,
  buqueFigura5,
  buqueFigura6,
  buqueFigura7,
  buqueFigura8
]
