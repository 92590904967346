import AgenciasMaritimasList from '../components/AgenciasMaritimasList'

export const AgenciasMaritimas = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        {/* <div className="card"> */}
        <AgenciasMaritimasList />
        {/* </div> */}
      </div>
    </div>
  )
}
